import React, { PureComponent } from 'react';
import '../App.css';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import StaticPage from "./StaticPage";
import Link from '@material-ui/core/Link';
import red from '@material-ui/core/colors/red';

const styles = theme => ({
    paper: {
        padding: theme.spacing.unit * 6,
        textAlign: 'center',
        color: red["500"],
    },
    h1: {
        fontSize: 100,
        margin: '25px 0',
    },
    h2: {
        fontSize: 50,
        margin: '20px 0'
    },
    h3: {
        fontSize: 25,
        margin: '15px 0'
    },
    link: {

    }
});

class NotFound extends PureComponent {
    render() {
        const { classes } = this.props;
        const content = (
            <Paper className={classes.paper} elevation={6}>
                <h1 className={classes.h1}>404</h1>
                <h2 className={classes.h2}>Not Found</h2>
                <h3 className={classes.h3}>Please contact <Link color="inherit" href="mailto:help@golfnotifications.com" className={classes.link}>help@golfnotifications.com</Link> if you need assistance.</h3>
            </Paper>
        );
        return (
            <StaticPage content={content} />
        );
    }
}

export default withStyles(styles)(NotFound);
