import 'whatwg-fetch'

export function requestSignup(requestData, successCallback, failureCallback){
    const url = 'https://0ji148yg3b.execute-api.us-east-1.amazonaws.com/prod/golfNotifications_signup';
    let errorMessage = "";

    function checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response
        } else {
            let error = new Error(response.statusText);
            error.response = response;
            throw error
        }
    }

    function parseJSON(response) {
        return response.json()
    }

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
    })
        .then(checkStatus)
        .then(parseJSON)
        .then(function(data) {
            if(data.success){
                successCallback(data);
            } else {
                errorMessage = data.message || 'An error occurred. Please contact help@golfnotifications.com.';
            }
        })
        .catch(function(error) {
            errorMessage = error.message;
        })
        .then(function(e) {
            if(errorMessage.length > 0){
                failureCallback(errorMessage);
            }
        });
}