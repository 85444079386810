import React from 'react';
import { Router, Route, Switch } from "react-router-dom";

import App from './App';

import NotFound from './Components/NotFound';
import Terms from './Components/Terms';
import Privacy from './Components/Privacy';
import history from './history';

const Routes = () => {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/"  component={App}/>
                <Route exact path="/terms" component={Terms}/>
                <Route exact path="/privacy" component={Privacy}/>
                <Route component={NotFound}/>
            </Switch>
        </Router>
    );
};

export default Routes;