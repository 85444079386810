import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import logo from "../Logos/GN_Logo_Text.svg";
import { Link } from "react-router-dom";

const styles = theme => ({
    root: {
        display: 'flex',
    },
    // appBar: {
    //     zIndex: theme.zIndex.drawer + 1,
    //     transition: theme.transitions.create(['width', 'margin'], {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.leavingScreen,
    //     }),
    // },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
});

const Navbar = () => {
    const theme = createMuiTheme(
        {
            typography: {
                useNextVariants: true,
            },
            palette: {
                primary: red,
                secondary: green
            }
        }
    );
    const appBarLogo = (
        <div className="logo-bar">
            <Link to={'/'}>
                <img src={logo} alt="logo" />
            </Link>
        </div>
    );
    return(
        <MuiThemeProvider theme={theme}>
            <AppBar position="static">
                <Toolbar>
                    {appBarLogo}
                </Toolbar>
            </AppBar>
        </MuiThemeProvider>
    )
}

export default withStyles(styles, { withTheme: true })(Navbar);