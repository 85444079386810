import React, { Component } from 'react';
import './App.css';
import {createMuiTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import bg from './landing-bg.jpg'
import logo from "./Logos/GN_Logo_Text_Alt.svg";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Footer from "./Components/Footer";
import MailIcon from '@material-ui/icons/Mail';
import TextIcon from '@material-ui/icons/Sms';
import MobileIcon from '@material-ui/icons/MobileScreenShare';
import VerifiedIcon from '@material-ui/icons/VerifiedUser';
import ManagerIcon from '@material-ui/icons/SupervisedUserCircle';
import FaceIcon from '@material-ui/icons/Face';
import PaintIcon from '@material-ui/icons/FormatPaint';
import PaletteIcon from '@material-ui/icons/Palette';
import FormatIcon from '@material-ui/icons/FormatShapes';
import TimelineIcon from '@material-ui/icons/Timeline';
import ChartIcon from '@material-ui/icons/BarChart';
import PhoneIcon from '@material-ui/icons/Phone';
import EventIcon from '@material-ui/icons/Event';
import DevicesIcon from '@material-ui/icons/Devices';
import ChatIcon from '@material-ui/icons/Chat';
import red from '@material-ui/core/colors/red';
import cyan from '@material-ui/core/colors/cyan';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import Pricing from "./Components/Pricing";
import Signup from "./Components/Signup";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { HashLink } from 'react-router-hash-link';

const styles = theme => ({
  root: {
    backgroundColor: '#e5e5e5'
  },
  landing: {

  },
  hero: {
    height: '100vh',
    margin: 0,
    border: 0,
    padding: 0,
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    maxHeight: 1000,
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    background: 'linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)), url(' + bg + ')'
  },
  logo: {
    maxWidth: 600,
    margin: 'auto',
    display: 'block'
  },
  grid: {
    ...theme.mixins.gutters(),
    display: 'flex',
    textAlign: 'center',
    marginBottom: 45
  },
  h1: {
    color: '#ddd',
    fontSize: '2rem',
    lineHeight: '1.5em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    }
  },
  h3: {
    color: '#ddd',
    fontSize: '1.25rem',
    lineHeight: '1.5em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    }
  },
  h2: {
    color: '#3C4858',
    fontSize: '2.25rem',
    lineHeight: '1.5em',
    margin: 10,
    textTransform: 'uppercase'
  },
  h4: {
    color: '#3C4858',
    fontSize: '1.125rem',
    lineHeight: '1.5em'
  },
  h5: {
    color: '#999',
    fontSize: '1.0625rem',
    lineHeight: '1.55em'
  },
  p: {
    color: '#999',
    fontSize: 14
  },
  gridWrapper: {
    // ...theme.mixins.gutters(),
    padding: theme.spacing.unit * 4,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing.unit * 2,
    }
  },
  paper: {
    zIndex: 3,
    minHeight: '90vh',
    padding: theme.spacing.unit * 1.5,
    marginTop: -120,
    position: 'relative'
  },
  gridInner: {
    // padding: theme.spacing.unit * 8,
  },
  features: {

  },
  pricingGrid: {
    ...theme.mixins.gutters(),
    display: 'flex',
    textAlign: 'center',
    background: 'linear-gradient(-25deg, #ffffff, #4caf5033 45%, #4caf504f 45%) #fff',
    minHeight: '100vh'
  },
  signupGrid: {
    ...theme.mixins.gutters(),
    display: 'flex',
    textAlign: 'center',
    minHeight: '100vh',
    background: 'linear-gradient(#ffffff, #ededed)'
  },
  iconLeft: {
    color: cyan["500"],
    fontSize: 35
  },
  iconMiddle: {
    color: green["500"],
    fontSize: 55
  },
  iconRight: {
    color: red["500"],
    fontSize: 35
  },
  appBar: {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none'
  },
  extendedIcon: {
    margin: '0 6px 0 0'
  }
});


class App extends Component {

  render() {
    const theme = createMuiTheme(
        {
          typography: {
            useNextVariants: true,
          },
          palette: {
            primary: red,
            secondary: blue
          }
        }
    );

    const { classes } = this.props;
    return (
        <div>
          <MuiThemeProvider theme={theme}>
            <div className={classes.root}>
              <AppBar position="absolute"  className={classes.appBar}>
                <Toolbar>
                  <Button color="inherit" component={props => <HashLink smooth to="/#Features" {...props}/>}>FEATURES</Button>
                  <Button color="inherit" component={props => <HashLink smooth to="/#Pricing" {...props}/>}>PRICING</Button>
                  <Button color="inherit" component={props => <HashLink smooth to="/#SignUp" {...props}/>}>SIGN-UP</Button>
                </Toolbar>
              </AppBar>
              <div className={classes.landing}>
                <div className={classes.hero}>
                  <Grid container spacing={24} className={classes.grid} justify="center" alignItems="center">
                    <Grid item xs={10} sm={8}>
                      <img className={classes.logo} src={logo} alt="logo"/>
                      <h1 className={classes.h1}>Notifications and Alerts for your Golf Course, Club, or Range</h1>
                      <h3 className={classes.h3}>Great for Weather Delays, Course Conditions, Events, and Marketing</h3>
                      {/*<Fab component={props => <Link to={'/Login'} {...props}/>} variant="extended" color="secondary" aria-label="submit" className={classes.button}>*/}
                        {/*<VerifiedIcon className={classes.extendedIcon} />*/}
                        {/*LOGIN*/}
                      {/*</Fab>*/}
                    </Grid>
                  </Grid>
                </div>
                <Grid className={classes.gridWrapper}>
                  <Paper className={classes.paper} elevation={12}>
                    <Grid container spacing={24} className={classes.gridInner} justify="center" alignItems="center">
                      <Grid item xs={12}>
                        <Grid container spacing={24} className={classes.grid} justify="space-evenly" alignItems="center">
                          <Grid id="Features" item xs={12}>
                            <h2 className={classes.h2}>Features</h2>
                            <h5 className={classes.h5}>We offer several features to help keep your members and visitors up-to-date on conditions, events, and more.</h5>
                          </Grid>
                        </Grid>
                        <Grid container spacing={24} className={classes.grid} justify="space-evenly" alignItems="center">
                          <Grid item xs={12} sm={5} md={4}>
                            <MailIcon className={classes.iconLeft}/><TextIcon className={classes.iconMiddle}/><MobileIcon className={classes.iconRight}/>
                            <h4 className={classes.h4}>Notify</h4>
                            <p className={classes.p}>We provide several options when it comes to choosing how you want to keep your golfers informed. You can choose between SMS, Email, and Push Notifications or use them all.</p>
                          </Grid>
                          <Grid item xs={12} sm={5} md={4}>
                            <FaceIcon className={classes.iconLeft}/><VerifiedIcon className={classes.iconMiddle}/><ManagerIcon className={classes.iconRight}/>
                            <h4 className={classes.h4}>Manage</h4>
                            <p className={classes.p}>If your golfers choose to create accounts, you'll be able to manage them. You can also add additional manager accounts to help with configuration and creating notifications.</p>
                          </Grid>
                          <Grid item xs={12} sm={5} md={4}>
                            <PaintIcon className={classes.iconLeft}/><PaletteIcon className={classes.iconMiddle}/><FormatIcon className={classes.iconRight}/>
                            <h4 className={classes.h4}>Customize</h4>
                            <p className={classes.p}>Notifications are customizable by type, via templating. Your emails will look professional and you can also customize themes for your business.</p>
                          </Grid>
                          <Grid item xs={12} sm={5} md={4}>
                            <ChatIcon className={classes.iconLeft}/><DevicesIcon className={classes.iconMiddle}/><PhoneIcon className={classes.iconRight}/>
                            <h4 className={classes.h4}>Lookup</h4>
                            <p className={classes.p}>In addition to sending notifications out, your community will also have the ability to view the current Status, Hours of Operation, and additional info about your business.</p>
                          </Grid>
                          <Grid item xs={12} sm={5} md={4}>
                            <TimelineIcon className={classes.iconLeft}/><EventIcon className={classes.iconMiddle}/><ChartIcon className={classes.iconRight}/>
                            <h4 className={classes.h4}>Review</h4>
                            <p className={classes.p}>You'll have access to data related to your notifications and users via a Dashboard, so you can understand the of the impact of the notifications you send.</p>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={24} className={classes.pricingGrid} justify="space-evenly" alignItems="center">
                          <Grid id="Pricing" item xs={12} sm={12} md={12}>
                            <h2 className={classes.h2}>Pricing</h2>
                            <Pricing/>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={24} className={classes.signupGrid} justify="space-evenly" alignItems="center">
                          <Grid id="SignUp" item xs={12} sm={8}>
                            <h2 className={classes.h2}>Sign Up</h2>
                            <h5 className={classes.h5}>If you're interested in signing up for our service, please provide your information below. We will be in touch soon. Please be sure to click Submit.</h5>
                            <Signup/>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </div>
              <Footer/>
            </div>
          </MuiThemeProvider>
        </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(App);