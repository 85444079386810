import React, { PureComponent } from 'react';
import '../App.css';
import CssBaseline from '@material-ui/core/CssBaseline';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Navbar from "./Navbar";
import Footer from "./Footer";

const styles = theme => ({
    root: {
        minHeight: '90vh',
        ...theme.mixins.gutters(),
        display: 'flex',
        paddingTop: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 2,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

class StaticPage extends PureComponent {
    render() {
        const { classes } = this.props;

        return (
            <div>
                <Grid>
                    <div>
                        <Navbar/>
                        <CssBaseline />
                        <div className={classes.root}>
                            <Grid container spacing={24} justify="center" alignItems="center">
                                <Grid item xs={12} sm={8}>
                                    {this.props.content}
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
                <Footer/>
            </div>
        );
    }
}

export default withStyles(styles)(StaticPage);
