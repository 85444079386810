import React, { Component } from 'react';
import { withStyles} from '@material-ui/core/styles';
import red from "@material-ui/core/colors/red";
import TextField from '@material-ui/core/TextField';
import green from "@material-ui/core/colors/green";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import {requestSignup} from '../utils'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    button: {
        margin: '8px auto'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    extendedIcon: {
        margin: '0 0 0 6px'
    },
    submitted: {
        width: '100%'
    }
});

class Signup extends Component {
    state = {
        name: '',
        email: '',
        phone: '',
        company: '',
        submitted: false,
        isLoading: false
    };

    handleChange(e){
        const name = e.target.name;
        const value = e.target.value;
        if(name === "name"){
            this.setState({name: value});
        }
        if(name === "company"){
            this.setState({company: value});
        }
        if(name === "email"){
            this.setState({email: value});
        }
        if(name === "phone"){
            this.setState({phone: value});
        }
    }

    submit(){
        const {name,email,phone,company} = this.state;
        this.setState({isLoading: true}, () => requestSignup({name,company,email,phone},this.requestSuccessful.bind(this),this.requestFailed.bind(this)))
    }

    requestSuccessful(data){
        this.setState({isLoading: false, submitted: true});
    }

    requestFailed(error){
        this.setState({isLoading: false}, () => alert(error));
    }

    render() {
        const {name,email,phone,company,submitted,isLoading} = this.state;
        const { classes } = this.props;
        const theme = createMuiTheme(
            {
                typography: {
                    useNextVariants: true,
                },
                palette: {
                    primary: red,
                    secondary: green
                }
            }
        );
        return (
            <form className={classes.container} noValidate autoComplete="off">
                    {submitted ? (
                        <div className={classes.submitted}>
                            <h2>Thank You!</h2>
                            <h4>We'll get back to you shortly</h4>
                        </div>
                    ) : (
                       <MuiThemeProvider theme={theme}>
                           <TextField
                               name="name"
                               style={{marginBottom: 12}}
                               required
                               fullWidth
                               className={classes.margin}
                               label="Name"
                               id="mui-theme-provider-standard-input"
                               value={name}
                               onChange={(e) => this.handleChange(e)}
                           />
                           <TextField
                               name="company"
                               style={{marginBottom: 12}}
                               required
                               fullWidth
                               className={classes.margin}
                               label="Company Name"
                               id="mui-theme-provider-standard-input"
                               value={company}
                               onChange={(e) => this.handleChange(e)}
                           />
                           <TextField
                               name="email"
                               style={{marginBottom: 12}}
                               required
                               fullWidth
                               className={classes.margin}
                               label="Email Address"
                               id="mui-theme-provider-standard-input"
                               value={email}
                               onChange={(e) => this.handleChange(e)}
                           />
                           <TextField
                               name="phone"
                               style={{marginBottom: 12}}
                               required
                               fullWidth
                               className={classes.margin}
                               label="Phone"
                               id="mui-theme-provider-standard-input"
                               value={phone}
                               onChange={(e) => this.handleChange(e)}
                           />
                           <Fab onClick={() => this.submit()} disabled={name.length === 0 || company.length === 0 || email.length === 0 || phone.length === 0 || isLoading} variant="extended" color="primary" aria-label="submit" className={classes.button}>
                               SUBMIT
                               <SendIcon className={classes.extendedIcon} />
                           </Fab>
                       </MuiThemeProvider>
                    )}
            </form>
        );
    }
}

export default withStyles(styles)(Signup);