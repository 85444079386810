import React, { PureComponent } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import { Link } from "react-router-dom";

const styles = theme => ({
    root: {
        backgroundColor: green["500"],
        color: '#fff',
        ...theme.mixins.gutters(),
        display: 'flex',
        paddingTop: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 2,
        textAlign: 'center'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        margin: theme.spacing.unit,
        color: '#fff'
    },
    link: {
        textDecoration: 'none'
    }
});

class Footer extends PureComponent {
    render() {
        const { classes } = this.props;

        return (
            <div>
                <CssBaseline />
                <div className={classes.root}>
                    <Grid container spacing={24} justify="center" alignItems="center">
                        <Grid item xs={10} sm={5}>
                            <Link className={classes.link} to={'/Privacy'}>
                                <Button className={classes.button}>
                                    Privacy Policy
                                </Button>
                            </Link>
                            <Link className={classes.link} to={'/Terms'}>
                                <Button className={classes.button}>
                                    Terms & Conditions
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                            © {(new Date().getFullYear())} GOLFNOTIFICATIONS.COM
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Footer);
