import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import red from "@material-ui/core/colors/red";
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { HashLink as Link } from 'react-router-hash-link';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    cardHeader: {
        backgroundColor: red[500],
        color: '#fff',
        paddingBottom: 90,
        fontWeight: 600,
        fontSize: '1.75rem',
    },
    cardHeaderPlus: {
        backgroundColor: red[500],
        color: '#fff',
        paddingBottom: 90,
        fontWeight: 600,
        fontSize: '1.75rem',
    },
    cardHeaderCustom: {
        backgroundColor: red[500],
        color: '#fff',
        paddingBottom: 90,
        fontWeight: 600,
        fontSize: '1.75rem',
    },
    cardContent: {
        backgroundColor: '#fefefe',
        minHeight: 400
    },
    cardPricing: {
        width: 150,
        height: 150,
        borderRadius: '50%',
        backgroundColor: 'white',
        padding: 40,
        boxShadow: '0 1px 4px hsla(0, 0%, 0%, 0.25)',
        margin: '-90px auto 25px auto',
    },
    cardActions: {
        [theme.breakpoints.up('sm')]: {
            paddingBottom: theme.spacing.unit * 2,
        },
    },
    button: {
        margin: '8px auto',
        textDecoration: 'none'
        // backgroundColor: red["500"],
        // color: '#fff',
    },
    h6: {
        color: '#3C4858',
        fontSize: '1.25rem',
        margin: 0
    },
    h2: {
        color: '#3C4858',
        fontSize: '2.25rem',
        margin: 0
    },
    h3: {
        color: '#3C4858',
        fontSize: '1.25rem',
        lineHeight: '1.5em'
    },
    list: {
        textAlign: 'left',
        '& li': {
            marginBottom: 10
        }
    }
});

class Pricing extends Component {
    render() {
        const { classes } = this.props;

        return (
            <div>
                <Grid container spacing={24} className={classes.grid} justify="center" alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <Card elevation={4}>
                            <CardHeader
                                title={'Standard'}
                                subheader={''}
                                disableTypography={true}
                                className={classes.cardHeader}
                            />
                            <CardContent className={classes.cardContent}>
                                <div className={classes.cardPricing}>
                                    <h2 className={classes.h2}>
                                        $99
                                    </h2>
                                    <h6 className={classes.h6}>
                                        /mo
                                    </h6>
                                </div>
                                <h3>
                                    <ul className={classes.list}>
                                        <li>Unlimited Email and Push Notifications</li>
                                        <li>Up to 2000 SMS Notifications per month</li>
                                        <li>Annual plan available (15% off)</li>
                                        <li>No contract required</li>
                                        <li>15 day trial available</li>
                                    </ul>
                                </h3>
                            </CardContent>
                            <CardActions className={classes.cardActions}>
                                <Button variant="outlined" className={classes.button} component={props => <Link smooth to="/#SignUp" {...props}/>}>
                                    Sign-Up
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card elevation={4}>
                            <CardHeader
                                title={'Plus'}
                                subheader={''}
                                disableTypography={true}
                                className={classes.cardHeaderPlus}
                            />
                            <CardContent className={classes.cardContent}>
                                <div className={classes.cardPricing}>
                                    <h2 className={classes.h2}>
                                        $149
                                    </h2>
                                    <h6 className={classes.h6}>
                                        /mo
                                    </h6>
                                </div>
                                <h3>
                                    <ul className={classes.list}>
                                        <li>Unlimited Email and Push Notifications</li>
                                        <li>Up to 10000 SMS Notifications per month</li>
                                        <li>Annual plan available (15% off)</li>
                                        <li>No contract required</li>
                                    </ul>
                                </h3>
                            </CardContent>
                            <CardActions className={classes.cardActions}>
                                <Button variant="outlined" className={classes.button} component={props => <Link smooth to="/#SignUp" {...props}/>}>
                                    Sign-Up
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card elevation={4}>
                            <CardHeader
                                title={'Custom'}
                                subheader={''}
                                disableTypography={true}
                                className={classes.cardHeaderCustom}
                            />
                            <CardContent className={classes.cardContent}>
                                <div className={classes.cardPricing}>
                                    <h2 className={classes.h2}>
                                        $99+
                                    </h2>
                                    <h6 className={classes.h6}>
                                        /mo
                                    </h6>
                                </div>
                                <h3>
                                    <ul className={classes.list}>
                                        <li>Customize your plan to fit your needs</li>
                                        <li>Requires minimum 1 year contract</li>
                                    </ul>
                                </h3>
                            </CardContent>
                            <CardActions className={classes.cardActions}>
                                <Button variant="outlined" className={classes.button} component={props => <Link smooth to="/#SignUp" {...props}/>}>
                                    Sign-Up
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
          </div>
        );
    }
}

export default withStyles(styles)(Pricing);


